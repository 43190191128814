import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import Moment from 'react-moment';

function Admin(props) {
    const { register, handleSubmit, watch, setError, formState: { errors } } = useForm();

    /**
     * Admin Access
     */
    const [hasAccess, setHasAccess] = useState(false);

    /**
     * Letters HTML
     */
    const [letters, setLetters] = useState([]);

    /**
     * Letter More Info HTML
     */
    const [letterMoreInfo, setLetterMoreInfo] = useState(<div></div>);

    const [formValue, setFormValue] = useState({
        password: '',
    });

    const [formValue2, setFormValue2] = useState({});

    const [formValueLetter, setFormValueLetter] = useState({
        emailLetter: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormValue((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        });
    };

    const handleChange2 = (event) => {
        const { checked, value } = event.target;

        setFormValue2((prevalue) => {
            return {
                ...prevalue,
                [value]: checked
            }
        });

    };

    /**
     * Get more information on order
     */
    const getInfo = (id) => {
        Axios.get("/getletteradmin", {
            params: {
                password: formValue.password,
                id: id,
            }
        }).then((response) => {
            // Set HTML
            setLetterMoreInfo(
                <div>
                    <p>
                        <b>Date Ordered:</b><br />
                        <Moment format="MM/DD/YYYY HH:MM:ss">
                            {response.data.result[0].dateOrdered}
                        </Moment>
                    </p>

                    <p>
                        <b>E-mail:</b><br />{response.data.result[0].email}
                    </p>

                    <p>
                        <b>Order Status:</b><br />
                        {response.data.result[0].orderStatus == 0 ? 'Preparing Order' : 'Shipped'}
                    </p>

                    <p>
                        <b>Type:</b><br />{response.data.result[0].type}
                    </p>

                    <p>
                        <b>Letter Text:</b><br />{response.data.result[0].letterText}
                    </p>

                    <p>
                        <b>Ship To:</b>
                        <br />
                        {response.data.result[0].addressStreet}
                        <br />
                        {response.data.result[0].addressCity}, {response.data.result[0].addressState} {response.data.result[0].addressZip}
                    </p>
                </div>
            );
        });
    };

    /**
     * User log into admin panel
     */
    const login = () => {
        Axios.post("/login", {
            password: formValue.password,
        }).then((response) => {
            if (response.data.auth) {
                Axios.get("/getlettersadmin", {
                    params: {
                        password: formValue.password
                    }
                }).then((response) => {
                    // Set up select array
                    let array = [];

                    // Iterate through results
                    for (let i = 0; i <= response.data.result.length - 1; i++) {
                        array.push(
                            <tr key={response.data.result[i].id}>
                                <td>
                                    <input type="checkbox" name="selection" value={response.data.result[i].id} onChange={handleChange2} />
                                </td>
                                <td>
                                    <Moment format="MM/DD/YYYY HH:mm:ss">
                                        {response.data.result[i].dateOrdered}
                                    </Moment>
                                </td>
                                <td><a href="#/" onClick={() => getInfo(response.data.result[i].id)}>{response.data.result[i].email}</a></td>
                                <td>
                                    {response.data.result[i].orderStatus == 0 ? 'Preparing Order' : 'Shipped'}
                                </td>
                                <td>
                                    <Moment format="MM/DD/YYYY HH:mm:ss">
                                        {response.data.result[i].orderStatusDate}
                                    </Moment>
                                </td>
                            </tr>
                        );
                    }

                    // Set array
                    setLetters([array]);
                });

                setHasAccess(true);
            } else {
                setError('password', { type: 'custom', message: response.data.message });
            }
        });
    };

    /**
     * Set as shipped
     */
    const setShipped = () => {
        Axios.post("/setshipped", {
            password: formValue.password,
            items: formValue2,
        }).then((response) => {
            Axios.get("/getlettersadmin", {
                params: {
                    password: formValue.password
                }
            }).then((response) => {
                // Set up select array
                let array = [];

                // Iterate through results
                for (let i = 0; i <= response.data.result.length - 1; i++) {
                    array.push(
                        <tr key={response.data.result[i].id}>
                            <td>
                                <input type="checkbox" name="selection" value={response.data.result[i].id} onChange={handleChange2} />
                            </td>
                            <td>
                                <Moment format="MM/DD/YYYY HH:mm:ss">
                                    {response.data.result[i].dateOrdered}
                                </Moment>
                            </td>
                            <td><a href="#/" onClick={() => getInfo(response.data.result[i].id)}>{response.data.result[i].email}</a></td>
                            <td>
                                {response.data.result[i].orderStatus == 0 ? 'Preparing Order' : 'Shipped'}
                            </td>
                            <td>
                                <Moment format="MM/DD/YYYY HH:mm:ss">
                                    {response.data.result[i].orderStatusDate}
                                </Moment>
                            </td>
                        </tr>
                    );
                }

                // Checkboxes
                setFormValue2({});

                // Set array
                setLetters([array]);
            });
        });
    };

    return (
        <div>
            <div className="center-content">
                {!hasAccess && (
                    <form method="POST" onSubmit={handleSubmit(login)}>
                        <div className="input-box">
                            <b>What is the admin password?</b>
                            <input
                                type="text"
                                {...register("password", {
                                    required: "Please enter a password."
                                })}
                                onChange={handleChange}
                            />

                            {errors.password && <span role="form-error">{errors.password.message}</span>}
                        </div>

                        <button type="submit">Login</button>
                    </form>
                )}

                {hasAccess && (
                    <div>
                        {letters.length > 0 && (
                            <span>
                                <table border="1" className="letter-table">
                                    <thead>
                                        <tr>
                                            <th>Selection</th>
                                            <th>Date Ordered</th>
                                            <th>Customer E-mail</th>
                                            <th>Order Status</th>
                                            <th>Last Update</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {letters}
                                    </tbody>
                                </table>

                                <button onClick={setShipped}>Mark Shipped</button>

                                {letterMoreInfo}
                            </span>
                        )}
                    </div>
                )}
            </div>
        </div >
    );
}

export default Admin;