import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import Axios from "axios";


// Set up Axios
Axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
//Axios.defaults.baseURL = 'http://localhost:3002/';
//Axios.defaults.baseURL = 'https://getmailfromsanta.com:8442';
Axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);