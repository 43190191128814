import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Letter from "./Pages/Letter";
import Complete from "./Pages/Complete";
import Admin from "./Pages/Admin";

function App() {
  /**
   * Window Location
   */
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * On page change
  */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="App">
      <header>
        <div className="container">
          <div className="row">
            <div className="col-12 p-0">
              <div className="main-image">
                <img src="/images/top-image.png" className="img-fluid" />
                <div className="main-text">
                  <h1>
                    <a href="/">Mail From Santa Claus</a>
                  </h1>
                  <div className="top-flower"><img src="/images/top-flower.png" className="img-fluid" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="content-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="middle-content">
                <Routes>
                  <Route path="/" element={<Letter />} />
                  <Route path="/complete" element={<Complete />} />
                  <Route path="/admin" element={<Admin />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12 p-0">
              <div className="main-image">
                <img src="/images/bottom-image.png" className="img-fluid" />
                <div className="main-text">
                  <div className="bottom-flower"><img src="/images/bottom-flower.png" className="img-fluid" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-adverts">
          <a href="https://www.santasnaughtyandnicelist.com">
            <img src="/images/santasnaughtyandnicelistcom_button.png" border="0" alt="Visit SantasNaughtyAndNiceList.com" />
          </a>

          <a href="https://www.santashub.com">
            <img src="/images/santashubcom_button.png" border="0" alt="Visit SantasHub.com" />
          </a>

          <a href="https://www.santaswishlistmaker.com">
            <img src="/images/santaswishcom_button.png" border="0" alt="Visit SantasWishlistMaker.com" />
          </a>

          <a href="https://easterlist.com">
            <img src="/images/easterbunny_button.png" border="0" alt="Visit EasterList.com" />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
