import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Complete(props) {
    return (
        <div>
            <p className="center-content">
                Thank you for your order! Your order has been received!
            </p>

            <p className="center-content">
                You can track your order from the homepage. On the homepage, press "Order Status" to check on your order.
            </p>
        </div >
    );
}

export default Complete;