import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import Moment from 'react-moment';
import FsLightbox from 'fslightbox-react';

/**
 * Stripe Client Key
 */
const stripePromise = loadStripe('pk_live_51I6MloLGZnqK1LXmsa6wJF7OmRwvb9SlqB58lrOvP0srEUo0YuAtoiphvnTHHxzvgZravKkCwsqgfv5xc6jc7P4d00XNe4YMOj');

function Letter(props) {
    const { register, handleSubmit, watch, setError, formState: { errors } } = useForm();

    /**
     * Fresh page - Has not pressed get started
     */

    const [getStarted, setGetStarted] = useState(false);

    /**
     * Check order status
     */

    const [orderStatus, setOrderStauts] = useState(false);

    /**
     * Letters HTML
     */
    const [letters, setLetters] = useState([]);

    /**
     * Loading Stripe - Is the form loading?
     */
    const [loadingStripe, setLoadingStripe] = useState(true);

    /**
     * Submit letter
     */
     const [formSubmit, setFormSubmit] = useState(false);

    /**
     * On page change
    */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [orderStatus]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [getStarted]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [formSubmit]);

    /**
     * Stripe secret
     */
    const [clientSecret, setClientSecret] = useState("");

    /**
     * Lightbox controller
     */
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    /**
     * Lightbox open slide on number
     */
    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    /**
     * Form - Type
     */
    const [type, setType] = useState("");

    const onChangeType = (event) => {
        setType(event.target.value);
    }

    /**
     * Handles submission
    */
    const submitForm = (e) => {
        setFormSubmit(true);
        // Create PaymentIntent as soon as the page loads
        fetch("https://getmailfromsanta.com:8442/create-payment-intent", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                item: "letter",
                type: type,
                childFirst: e.childFirst,
                childLast: e.childLast,
                childGender: e.childGender,
                childCity: e.childCity,
                childFriend: e.childFriend,
                childRelation: e.childRelation,
                childWish: e.childWish,
                letterAccomplishment: e.letterAccomplishment,
                letterImprove: e.letterImprove,
                letterText: e.letterText,
                email: e.email,
                addressStreet: e.addressStreet,
                addressCity: e.addressCity,
                addressState: e.addressState,
                addressZip: e.addressZip,
            }),
        })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret));
    }

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    /**
     * Get letters from server
     */

    const getLetters = (e) => {
        Axios.get("/getletters", {
            params: {
                email: e.emailLetter,
            }
        }).then((response) => {
            // Set up select array
            let array = [];

            // Iterate through results
            for (let i = 0; i <= response.data.result.length - 1; i++) {
                array.push(
                    <tr key={i}>
                        <td>
                            <Moment format="MM/DD/YYYY HH:mm:ss">
                                {response.data.result[i].dateOrdered}
                            </Moment>
                        </td>
                        <td>{response.data.result[i].childFirst}</td>
                        <td>
                            {response.data.result[i].orderStatus == 0 ? 'Preparing Order' : 'Shipped'}
                        </td>
                        <td>
                            <Moment format="MM/DD/YYYY HH:mm:ss">
                                {response.data.result[i].orderStatusDate}
                            </Moment>
                        </td>
                    </tr>
                );
            }

            // Set array
            setLetters([array]);
        });
    };

    if (orderStatus) {
        return (
            <div>
                <div className="center-content">
                    <form method="POST" onSubmit={handleSubmit(getLetters)}>
                        <div className="input-box">
                            <b>What is the e-mail address you used for the order?</b>
                            <input
                                type="text"
                                {...register("emailLetter", {
                                    required: "Please enter an e-mail address.",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Enter a valid e-mail address."
                                    }
                                })}
                            />

                            {errors.emailLetter && <span role="form-error">{errors.emailLetter.message}</span>}
                        </div>

                        <button type="submit">Find Orders</button>
                    </form>
                </div>

                <div className="center-content">
                    {letters.length > 0 && (
                        <div>
                            <table border="1" className="letter-table">
                                <thead>
                                    <tr>
                                        <th>Date Ordered</th>
                                        <th>Child Name</th>
                                        <th>Order Status</th>
                                        <th>Last Update</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {letters}
                                </tbody>
                            </table>

                            <p>
                                <a href="mailto:support@drennansoftware.com">Need customer service? E-mail us here.</a>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        )
    } else {
        if (!getStarted) {
            return (
                <div>
                    <h4>Send your child the gift of a letter from Santa Claus! </h4>
                    <p>
                        All letters are printed on a old style paper, placed inside a Christmas mailing envelope, and hand stamped with a wax seal. The letter is then mailed to your address for your child to open and enjoy. The letters can be customized or you can use our easy to use template to make a letter.
                    </p>

                    <h4>Fast Shipping and Money Back Gurantee!</h4>

                    <p>
                        <i>Scroll down to get started!</i>
                    </p>

                    <div className="center-content">
                        <a href="#/" onClick={() => openLightboxOnSlide(1)}>
                            <img src="/images/letter1.jpeg" width="75%" height="500px" />
                        </a>
                        <a href="#/" onClick={() => openLightboxOnSlide(2)}>
                            <img src="/images/letter2.jpeg" width="75%" height="500px" />
                        </a>
                        <a href="#/" onClick={() => openLightboxOnSlide(3)}>
                            <img src="/images/letter3.jpeg" width="75%" height="500px" />
                        </a>
                        <a href="#/" onClick={() => openLightboxOnSlide(4)}>
                            <img src="/images/letter4.jpeg" width="75%" height="500px" />
                        </a>
                        <FsLightbox
                            toggler={lightboxController.toggler}
                            sources={[
                                '/images/letter1.jpeg',
                                '/images/letter2.jpeg',
                                '/images/letter3.jpeg',
                                '/images/letter4.jpeg',
                            ]}
                            slide={lightboxController.slide}
                        />

                        <p className="center-content">
                            <button onClick={() => setGetStarted(true)}>Get Started!</button>
                        </p>

                        <p className="center-content">
                            <button onClick={() => setOrderStauts(true)}>Order Status</button>
                        </p>
                    </div>
                </div>
            )
        } else {
            if (!formSubmit) {
                return (
                    <div>
                        <form method="POST" onSubmit={handleSubmit(submitForm)}>
                            <div className="input-box">
                                <b>Do you want to write your own letter or would you like to use our template?</b>
                            </div>

                            <div className="input-box">
                                <div onChange={onChangeType}>
                                    <input type="radio"
                                        name="childType"
                                        value="Template"
                                    /> Template
                                    <br />
                                    <input
                                        type="radio"
                                        name="childType"
                                        value="Custom"
                                    /> Write my own
                                </div>
                            </div>

                            {type != "" && (
                                <div>
                                    {type == "Template" && (
                                        <div>
                                            <div className="input-box">
                                                <b>What is your child's first name?</b>
                                                <input type="text"
                                                    {...register("childFirst", {
                                                        required: "Please enter the child's fist name."
                                                    })}
                                                />

                                                {errors.childFirst && <span role="form-error">{errors.childFirst.message}</span>}
                                            </div>

                                            <div className="input-box">
                                                <b>What is your child's last name?</b>
                                                <input type="text"
                                                    {...register("childLast", {
                                                        required: "Please enter the child's last name."
                                                    })}
                                                />

                                                {errors.childLast && <span role="form-error">{errors.childLast.message}</span>}
                                            </div>

                                            <div className="input-box">
                                                <b>What is your child's gender?</b>
                                                <br />
                                                <input type="radio" value="Male"
                                                    {...register("childGender", {
                                                        required: "Please select the gender of the child."
                                                    })}
                                                /> Boy
                                                <br />
                                                <input type="radio" value="Female"
                                                    {...register("childGender", {
                                                        required: "Please select the gender of the child."
                                                    })}
                                                /> Girl

                                                {errors.childGender && <span role="form-error">{errors.childGender.message}</span>}
                                            </div>

                                            <div className="input-box">
                                                <b>What city do you live in?</b>
                                                <input type="text"
                                                    {...register("childCity", {
                                                        required: "Please enter the city the child lives in."
                                                    })}
                                                />

                                                {errors.childCity && <span role="form-error">{errors.childCity.message}</span>}
                                            </div>

                                            <div className="input-box">
                                                <b>What is a name of a family member or friend the child is familiar with?</b>
                                                <input type="text"
                                                    {...register("childFriend", {
                                                        required: "Please enter a family member or friend."
                                                    })}
                                                />

                                                {errors.childFriend && <span role="form-error">{errors.childFriend.message}</span>}
                                            </div>

                                            <div className="input-box">
                                                <b>What is the relation to the child?</b>
                                                <input type="text"
                                                    {...register("childRelation", {
                                                        required: "Please enter the family members or friends relation to child."
                                                    })}
                                                />

                                                {errors.childRelation && <span role="form-error">{errors.childRelation.message}</span>}
                                            </div>

                                            <div className="input-box">
                                                <b>What does your child want for Christmas?</b>
                                                <input type="text"
                                                    {...register("childWish", {
                                                        required: "Please enter what your child wants for Christmas."
                                                    })}
                                                />

                                                {errors.childWish && <span role="form-error">{errors.childWish.message}</span>}
                                            </div>

                                            <div className="input-box">
                                                <b>What is an accomplishment your child accomplished this year?</b>
                                                <input type="text"
                                                    {...register("letterAccomplishment", {
                                                        required: "Please enter what your child acheived."
                                                    })}
                                                />

                                                {errors.letterAccomplishment && <span role="form-error">{errors.letterAccomplishment.message}</span>}
                                            </div>

                                            <div className="input-box">
                                                <b>What does your child have to work on?</b>
                                                <input type="text"
                                                    {...register("letterImprove", {
                                                        required: "Please enter what your child needs to work on."
                                                    })}
                                                />

                                                {errors.letterImprove && <span role="form-error">{errors.letterImprove.message}</span>}
                                            </div>
                                        </div>
                                    )}

                                    <div className="input-box">
                                        <b>What do you want the letter to say?</b>
                                        <textarea type="text"
                                            {...register("letterText", {
                                                required: "Please enter your letter message."
                                            })}
                                        ></textarea>

                                        {errors.letterText && <span role="form-error">{errors.letterText.message}</span>}
                                    </div>

                                    <div className="input-box">
                                        <b>What is your e-mail address?</b>
                                        <input
                                            type="text"
                                            {...register("email", {
                                                required: "Please enter an e-mail address.",
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: "Enter a valid e-mail address."
                                                }
                                            })}
                                        />

                                        {errors.email && <span role="form-error">{errors.email.message}</span>}
                                    </div>

                                    <div className="input-box">
                                        <b>Street Address:</b>
                                        <input
                                            type="text"
                                            {...register("addressStreet", {
                                                required: "Please enter your street address."
                                            })}
                                        />

                                        {errors.addressStreet && <span role="form-error">{errors.addressStreet.message}</span>}
                                    </div>

                                    <div className="input-box">
                                        <b>City:</b>
                                        <input
                                            type="text"
                                            {...register("addressCity", {
                                                required: "Please enter your city."
                                            })}
                                        />

                                        {errors.addressCity && <span role="form-error">{errors.addressCity.message}</span>}
                                    </div>

                                    <div className="input-box">
                                        <b>State:</b>
                                        <input
                                            type="text"
                                            {...register("addressState", {
                                                required: "Please enter your state."
                                            })}
                                        />

                                        {errors.addressState && <span role="form-error">{errors.addressState.message}</span>}
                                    </div>

                                    <div className="input-box">
                                        <b>Zip Code:</b>
                                        <input
                                            type="text"
                                            {...register("addressZip", {
                                                required: "Please enter your zip code.",
                                                pattern: {
                                                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                                    message: "Please enter a valid zip code."
                                                }
                                            })}
                                        />

                                        {errors.addressZip && <span role="form-error">{errors.addressZip.message}</span>}
                                    </div>

                                    <div className="input-box">
                                        <button type="submit">Next &#62;&#62;</button>
                                    </div>

                                </div>
                            )}
                        </form >
                    </div >
                );
            } else {
                return (
                    <div className="center-content">
                        {loadingStripe && (
                            <img src="/images/loading.gif" />
                        )}

                        {!loadingStripe && (
                            <h3>Purchase Letter: $9.99</h3>
                        )}

                        <div className="StripeForm">
                            {clientSecret && (
                                <Elements options={options} stripe={stripePromise}>
                                    <CheckoutForm setLoadingStripe={setLoadingStripe} />
                                </Elements>
                            )}
                        </div>
                    </div>
                )
            }
        }
    }
}

export default Letter;